























































import Vue from 'vue';
import { mapGetters } from 'vuex';
import WordTable from '@/components/Presenter/SessionReport/WordTable.vue';
export default Vue.extend({
  components: { WordTable },
  props: {
    wordcloud: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
    }),
    words(): any[] {
      if (this.wordcloud) {
        const list = [];
        for (const key in this.wordcloud) {
          if (key !== 'id') {
            list.push([key, this.wordcloud[key]]);
          }
        }
        return list;
      }
      return [];
    },
  },
  methods: {
    csvExport() {
      let csvContent = 'data:text/csv;charset=utf-8,';
      csvContent += [
        'Wort;Vote',
        ...Object.keys(this.wordcloud).map(
          (key) =>
            `${key.replace(/,/g, ' ').replace(/(?:\r\n|\r|\n)/g, ' ')};${
              this.wordcloud[key]
            }`
        ),
      ].join('\n');

      const data = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute(
        'download',
        `${this.currentSession.name}-Modul-Wortwolke-#${this.id + 1}.csv`
      );
      link.click();
    },
  },
});
